import * as React from 'react';
import { ThemeProvider,  createTheme } from '@mui/material/styles';

import App from './components/App/App'
import { blue, red } from '@mui/material/colors';

// Redux
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxThunk from 'redux-thunk';

import rootReducer from './state/reducer';

// import * as serviceWorker from './serviceWorker';
// import Firebase from './firebase';

const store = createStore(rootReducer, composeWithDevTools(
  applyMiddleware(reduxThunk) 
));


// const darkTheme = createTheme({
//     // typography: {
//     //     fontFamily: 'Nunito Sans, Arial',
//     // },
//     palette: {
//       mode: 'dark',
//       primary: blue, // '#1184dd',
//       // secondary: '#f50057', //'#e92a61',
//       secondary: {
//         light: '#0066ff',
//         main: '#f50057',
//         // dark: will be calculated from palette.secondary.main,
//         contrastText: '#ffcc00',
//       },
//       error: red,
//       contrastText: '#999',
//       contrastThreshold: 3,
//       tonalOffset: 0.2,
//     //   backgroundColor: '#676767 !important'
//     },
//     overrides: {
//       MuiButton: {
//         root: {
//           borderRadius: 8,
//         }, 
//       }, 
//     }, 
//   });
  
  const ColorModeContext = React.createContext({ toggleColorMode: () => {} });
  

export default function AppContext() {
  const [mode, setMode] = React.useState('light');
//   const classes = useStyles();

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
        },

        palette: {
          mode,
          primary: blue, // '#1184dd',
          // secondary: '#f50057', //'#e92a61',
          secondary: {
            light: '#0066ff',
            main: '#f50057',
            // dark: will be calculated from palette.secondary.main,
            contrastText: '#ffcc00',
          },
          neutral: {
            main: '#64748B',
            contrastText: '#fff',
          },
          error: red,
          contrastText: '#999',
          contrastThreshold: 3,
          tonalOffset: 0.2,
        },
        // toolbar: theme.mixins.toolbar,

      }),
    [mode],
  );
  
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Provider store={ store }>
          <App />
        </Provider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

// const drawerWidth = 240;

