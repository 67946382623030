import produce from 'immer';

import {
  ERRORS_ADD,
  ERRORS_CLEAR,
  TOASTS_ADD,
  TOASTS_REMOVE,
  WIPE,
  CHANGE_STATE,
  SOUNDS_ADD,
  SOUNDS_REMOVE,
  ENABLE_SOUND,
  DISABLE_SOUND,
  ENABLE_REFRESH_CONTACTS,
  DISABLE_REFRESH_CONTACTS,
  INIT_USER,
  CHANGE_USERSMENU_STATE
} from './constants';

export const initialState = {
  errors: [],
  toasts: {
    lastId: 0,
    items : []
  },
  sounds : [],
  menu : {isOpen : false },
  menuUsers : {isOpen : false},
  config : { sonidoActivo : true, contactsRefresh : false }
};

const reducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {

    case INIT_USER:
      if(localStorage.getItem('userSound')){
        draft.config.sonidoActivo = JSON.parse(localStorage.getItem('userSound'));
      }else{
        localStorage.setItem('userSound', draft.config.sonidoActivo.toString());
      }

      if(localStorage.getItem('contactsRefresh')){
        draft.config.contactsRefresh = JSON.parse(localStorage.getItem('contactsRefresh'));
      }else{
        localStorage.setItem('contactsRefresh', draft.config.contactsRefresh.toString());
      }

    break;

    case ERRORS_ADD:
      draft.errors.push(action.data);
      break;

    case ERRORS_CLEAR:
      draft.errors = initialState.errors;
      break;

    case TOASTS_ADD:
      draft.toasts.items.push(action.data);
      break;

    case TOASTS_REMOVE:
      draft.toasts.items = draft.toasts.items.filter(item => item.id !== action.data);
      break;

    case SOUNDS_ADD:
      draft.sounds.push(action.data);
      break;

    case SOUNDS_REMOVE:
      draft.sounds = draft.sounds.filter(item => item.id !== action.data);
      break;

    case ENABLE_SOUND:
      draft.config.sonidoActivo = true;
      localStorage.setItem('userSound', draft.config.sonidoActivo.toString())
      break;
  
    case DISABLE_SOUND:
      draft.config.sonidoActivo = false;
      localStorage.setItem('userSound', draft.config.sonidoActivo.toString())
      break;
    
    case ENABLE_REFRESH_CONTACTS:
      draft.config.contactsRefresh = true;
      localStorage.setItem('contactsRefresh', draft.config.contactsRefresh.toString())
      break;
    
    case DISABLE_REFRESH_CONTACTS:
      draft.config.contactsRefresh = false;
      localStorage.setItem('contactsRefresh', draft.config.contactsRefresh.toString())
      break;

    case CHANGE_STATE:
        draft.menu.isOpen = !draft.menu.isOpen;
        break;

    case CHANGE_USERSMENU_STATE:
        draft.menuUsers.isOpen = !draft.menuUsers.isOpen;
        break;  

    case WIPE:
      draft.errors = initialState.errors;
      draft.toasts = initialState.toasts;
      break;

    default:
  }
});

export default reducer;
